<template>
  <div>
    <template v-if="contactsList.length">
      <b-form-checkbox-group v-model="selectedInbox">
        <b-card no-body class="mb-1">
          <b-card-header class="p-0 justify-content-end">
            <small>حذف المحدد</small>
            <b-button
              size="sm"
              variant="flat-secondary"
              class="btn-icon rounded-circle"
              style="padding: 0.5rem 10px !important"
              :disabled="!selectedInbox.length"
              @click="deleteMultiContacts(selectedInbox)"
            >
              <unicon name="trash-alt" width="18"></unicon>
            </b-button>
          </b-card-header>
        </b-card>
        <div v-for="(inbox, index) in contactsList" :key="index">
          <b-card no-body class="mb-1">
            <b-card-header class="p-1">
              <div class="d-flex">
                <b-form-checkbox class="mr-25" :value="inbox.id"></b-form-checkbox>
                <span>المرسل: {{ inbox.name }}</span>
              </div>
              <span>العنوان: {{ inbox.subject }} </span>

              <span
                >التاريخ: {{ new Date(inbox.sendDate).toLocaleDateString("en-UK") }}</span
              >
              <div class="d-flex align-items-center">
                <b-badge
                  class="ml-auto mr-50 p-50 rounded-circle h-100"
                  variant="success"
                  v-if="inbox.response"
                  v-b-tooltip.hover.v-success
                  title="تم الرد"
                  ><unicon name="check" fill="#fff"
                /></b-badge>

                <b-button
                  size="sm"
                  variant="flat-secondary"
                  class="btn-icon rounded-circle"
                  style="padding: 0.5rem 10px !important"
                  :to="'/admin/contacts/' + inbox.id"
                >
                  <unicon name="ellipsis-v" width="18"></unicon>
                </b-button>
              </div>
            </b-card-header>
          </b-card>
        </div>
      </b-form-checkbox-group>
      <b-col cols="12" class="d-flex justify-content-center">
        <EKPagination :items="contacts" v-model="activeListMainData" :pageLength="8" />
      </b-col>
    </template>
    <div style="height:65vh;" class="d-flex align-items-center justify-content-center" v-else>

    <h3 class="text-center" >لا يوجد رسائل في البريد</h3>
    </div>
  </div>
</template>
<script>
import { mapActions,mapGetters } from "vuex";
import EKPagination from "@Ekcore/components/EK-pagination";

export default {
  components: {
    EKPagination,
  },
  data: () => ({
    selectedInbox: [],
    activeListMainData: [],
  }),
  computed: {
    ...mapGetters(['contactsList']),
  },
  created() {
    this.fetchContacts();
  },
  methods: {
    ...mapActions(["fetchContacts", "deleteMultiContacts"]),
  },
};
</script>
<style lang="scss" scoped>
.filter-radius .custom-radio {
  display: inline-block;
  &:not(:first-of-type) {
    margin-left: 0.5rem;
  }
}
</style>
